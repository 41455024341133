import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

interface SEOProps {
  description?: string
  lang?: string
  meta?: [
    {
      name: string
      content: string
    }
  ]
  title?: string
  keywords?: string[]
}

const SEO: React.FunctionComponent<SEOProps> = ({ description = "", lang = "en", meta = [], title, keywords = [] }) => {
  const siteData = useStaticQuery(
    graphql`
      {
        sanitySiteSettings {
          siteAuthor
          siteDescription
          siteTitle
          workAvailability
        }
      }
    `
  )

  const data = siteData.sanitySiteSettings

  const metaDescription = description || data.siteDescription

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`${data.siteTitle} — %s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: "keywords",
          content: keywords.join(","),
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: data.siteAuthor,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta || [])}
    ></Helmet>
  )
}

export default SEO
